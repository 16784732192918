/*
 * content
 */
.login-area{
  table{
    margin: 30px auto 10px;
    width: 300px;
    border-collapse: separate;
    border-spacing: 2px;
    th{
      background: #1475a7;
      color: #fff;
      font-weight: normal;
      width: 100px;
    }
    th,
    td{
      padding: 3px;
    }
  }
}
